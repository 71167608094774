* {
  box-sizing: border-box;
  /* used this line whenever i needed to be able to visualize the boxes */
  /* border: 1px solid blue; */
}

body {
  margin: 0;
}

/*desktop styling*/
@media screen and (min-width: 801px) {
  .siteTitle {
    font-size: 2rem;
    color: white;
  }

  .navInput {
    color: white;
    font-size: 1.5em;
    padding: 5px;
  }

  .navInput input {
    font-size: 0.75em;
    width: 100%;
    /* background-color: transparent; */
    border-color: black;
    border-radius: 8px;
  }

  .nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    padding: 0 1rem;
    /* padding: 10px; */
    background-color: var(--secondary-background-color);
    text-align: center;
  }

  .nav ul {
    text-align: center;
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 1rem;
    font-size: 1.5em;
  }

  .nav a {
    color: inherit;
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0.25rem;
  }

  .nav li.active {
    color: var(--primary-color);
    border-radius: 10px;
  }

  .nav li {
    color: white;
    -webkit-transition: color 0.125s;
    -moz-transition: color 0.125s;
    -ms-transition: color 0.125s;
    -o-transition: color 0.125s;
    transition: color 0.125s;
  }

  .nav li:hover {
    /* background-color: #777; */
    color: var(--primary-hover);
    border-radius: 10px;
  }

  .navButtons {
    display: flex;
    gap: 1rem;
  }

  .league-dropdown select {
    background-color: transparent;
    color: white;
    border: 1px solid var(--primary-color);
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    outline: none;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    transition: border-color 0.3s ease;
  }

  .league-dropdown select:hover {
    border-color: var(--primary-hover);
  }

  .league-dropdown select option:hover {
    background-color: var(--primary-color);
    color: black;
  }

  .league-dropdown {
    position: relative;
    display: inline-block;
    align-content: center;
  }

  /* .league-dropdown::after {
    content: "▼";
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
    color: white;
  } */
}

@media screen and (max-width: 800px) {
  .nav {
    display: flex;
    background: var(--secondary-background-color); /* Navbar background color */
    color: white; /* Navbar text color */
    padding: 10px;
  }

  .nav li.active {
    color: var(--primary-color);
    border-radius: 10px;
  }

  .hamburger {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .mobileMenu {
    position: absolute;
    top: 50px; /* Adjust this based on your navbar height */
    left: 0;
    background: var(--secondary-background-color); /* Mobile menu background */
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    z-index: 1000; /* Ensure it appears on top */
  }

  .mobileMenu ul {
    list-style: none;
    padding: 0;
  }

  .mobileMenu li {
    margin: 10px 0;
  }

  .mobileMenu a {
    color: white; /* Link color */
    text-decoration: none; /* Remove underline */
    font-size: 1.2rem; /* Adjust font size */
  }

  .navButtons {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }
}
