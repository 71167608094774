.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: var(--secondary-background-color);
  padding: 20px;
  border-radius: 5px;
  width: 300px;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.modal-header {
  color: white;
  font-size: 20px;
}

.modal-body {
  padding: 10px;
}

.error {
  color: red;
  font-size: 12px;
  word-spacing: 10%;
}

.modal-buttons {
  margin: 5px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.league-input {
  width: 100%;
  /* height: 100%; */
  border-radius: 12px;
}
